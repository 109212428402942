export const englishDailySaleReport = {
  main_title: "Daily Sale Report",
  detail_title: "Sale Detail Report",
  start_date: "From Date",
  end_date: "To Date",
  purpose_of_use: "Sale Type",
  nozzle_no: "Pump/ Nozzle No.",
  fuel_type: "Fuel Type",
  station: "Station",
  amount: "Amount",
  casher: "Cashier",
  toExcel: "To Excel",
  toExcelDaily: "Export Excel by Date",
  toPrint: "To PDF",
  search: "SEARCH",
  title: "Daily Sale Report Tabel of ",
  total_record: "Total Records",
  pprd_no: "PPRD License No.",
  vocono: "Invoice ID",
  cashType: "Sale Type",
  sale_date_time: "Date & Time",
  vehicle_no: "Vehicle No",
  sale_gallon: "Total (Gallon)",
  sale_liter: "Total (Liter)",
  sale_price: "Selling Price (Kyats)",
  total_price: "Total Amount (Kyats)",
  totalizer_liter: "Totalizer No. (Liter)",
  totalizer_amount: "Totalizer Amount",
  Station_name: "Station Name", //update
  Township: "Township",
  State: "State/Division",
  Open: "Opening Stock (Gallon)",
  Close: "Closing Stock (Gallon)",
  Sale: "Sale Volume (Gallon)",
  Adj: "Adjustment (Gallon)",
  Remark: "Remark",
  receive: "Received (Gallon)",
  pump_title: "Pump Report",
};
